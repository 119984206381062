import {
  Component,
  ContentChildren,
  QueryList,
  AfterContentInit,
} from '@angular/core';

import TabComponent from './tab.component';

@Component({
  selector: 'ngx-tabs',
  template: `
    <div class="tabs-container">
      <ul class="nav nav-tabs">
        <li *ngFor="let tab of tabs" class="tab" [ngClass]="{'active': tab.active === true }" (click)="selectTab(tab)">{{ tab.tabTitle }} </li>
      </ul>
      <ng-content></ng-content>
    </div>
  `,
  styles: [
    `
      .tabs-container {
        margin:2px;
        border-bottom:none;
      }
      .tabs-container .tab{
        padding:5px;
        flex: auto;
        text-align:center;
        width:50px;
        font-family: 'Montserrat';
        font-size: 40px;
        line-height: 49px;
        text-align: center;
        letter-spacing: -0.02em;
        // background-color:lightgray;
        cursor: pointer;
border:none;
      }
      .tabs-container .tab:hover{
         
         border-radius:2px;
           opacity:90%;
      }
       .tabs-container .tab.active{
         color:#F97763;
         //background-color:gray;
        //  border-bottom:2px solid blue;
         }
         .nav-tabs {
          margin-top: 6rem;
          margin-bottom: 6rem;
          border-bottom: 0px !important;
      }
    `,
  ],
})
export default class TabsComponent implements AfterContentInit {
  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;
  constructor() {
    console.log(this.tabs);
  }
  ngAfterContentInit() {
    console.log(this.tabs);
    const activeTabs = this.tabs.filter((tab) => tab.active);

    if (activeTabs.length === 0) {
      this.selectTab(this.tabs.first);
    }
  }

  selectTab(tab: TabComponent) {
    this.tabs.toArray().forEach((tab) => (tab.active = false));
    tab.active = true;
  }
}
