import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { EmailService } from './../Services/email.service';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent implements OnInit {

  IsSent: boolean = true;

  constructor(private router: Router, private emailService: EmailService, private formBuilder: FormBuilder) { }

  contactUsForm = this.formBuilder.group({
    name: '',
    email: '',
    message: ''
  });

  clearForm() {
    this.contactUsForm.reset();
  }

  ngOnInit(): void {

  }

  SendEmail() {
    this.emailService.SendEmail(this.contactUsForm.value).subscribe((Data: any) => {
      if (Data.status === "success") {
        this.clearForm();
        this.IsSent = false;
      } else {
      }
    });
  }

}
