<!-- 
<section id="footer">
  <div class="container">
    <div class="row text-sm-left pt-5 text-md-left">
      <div class="col-md-6">
        <div class="row">
          <div class="col-lg-10">
            <div class="contact-form" style="
                border-radius: 20px;
                background-color: white;
                padding: 20px;
              ">
              <div class="form-group col-sm-12">
                <label class="control-label footerHeading">Contact Us</label>
              </div>

              <div class="form-group col-sm-12 contact_profile">
                <label class="control-label">Your Name</label>
                <input type="text" class="form-control nameInput" id="fname" placeholder="Enter Your Name" name="fname"
                  autocomplete="off" />
              </div>
              <div class="form-group">
                <label class="control-label col-sm-12">Email</label>
                <div class="col-sm-12 contact_email input">
                  <input type="email" class="form-control emailInput" id="email" placeholder="Enter Your Email" name="email"
                    autocomplete="off" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" />
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-12">Message </label>
                <div class="col-sm-12 comment_area">
                  <textarea class="form-control commentInput" rows="5" name="message" id="comment"
                    placeholder="Write Your Message..." autocomplete="off"></textarea>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-offset-2 col-sm-10">
                  <button type="submit" class="btn btnContact">
                    <img style="margin-right: 10px;" src="../../../assets/images/send.svg" /> Send Message
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 align-self-center">
        <div class="row">
          <div class="col-md-12">
            <h2 class="footerHeading">Join us on our journey for better</h2>
          </div>
          <div class="col-md-12">
            <h6 class="faddress">
              Address: 4800 Leslie St , North York, ON M2J 2K9 <br />
              Email: info@myjourney.health
            </h6>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-5"></div>
      <hr />
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-5"></div>
      <hr />
    </div>
  </div>
</section>

<section class="container-fluid" id="footer-Bottom">
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-2 text-center">
      <div>
        <h6>
          <img src="../../../assets/images/JourneyLogoB.svg" alt="" /><a routerLink="" (click)="privacyPolicy()" style="
              color: black;
              text-decoration: none;
              margin-left: 15px;
              cursor: pointer;
            ">Privacy Policy</a>
          . Health Care Statement
        </h6>
      </div>
    </div>
    <hr />
  </div>
</section> -->

<section class="container-fluid" id="footer-Bottom">
  <div class="row cn-ftr">
    <div class="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-2 text-center">
      <div>
        <h6>
          <a routerLink="" (click)="privacyPolicy()" class="pr-policy">Privacy Policy</a>
          . Health Care Statement
          <img src="../../../assets/images/JourneyLogoB.svg" alt=""  class="img-ftr"/>
        </h6>
      </div>
    </div>
    <hr />
  </div>
</section>