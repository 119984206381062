<nav
  class="navbar navbar-expand-lg navbar-light bg-light shadow-sm nav-spacing"
>
  <a class="navbar-brand" href="#">
    <img src="../../../assets/images/Journey-Logo.svg" alt="Logo" />
  </a>
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto p-3 font-weight-bold cn-hr">

      <li class="nav-item">
        <a class="nav-link navBtn" href="/AboutUs">About Us</a>
      </li>

      <li class="nav-item" style="margin-left: 20px">
        <a class="nav-link navBtn" href="/Products">Products</a>
      </li>
      <!-- <li  class="nav-item" style="margin-left: 20px">
        <a class="nav-link HandPointer navBtn" href="javascript:void(0);">Resouces</a>
      </li> -->
      <li  class="nav-item" style="margin-left: 20px">
        <a class="nav-link HandPointer navBtn" href="ContactUs">Contact Us</a>
      </li>
    </ul>
    <form class="form-inline my-2 my-lg-0">
      <a href="https://www.linkedin.com/company/myjourney-health/" target="_blank">
        <span>
        <img style="margin-right: 30px;" src="../../../assets/images/linkedin.svg" />
      </span>
      </a>
      <a href="https://www.facebook.com/profile.php?id=100089164520771" target="_blank">
        <span>
          <img style="margin-right: 10px;" src="../../../assets/images/Facebook.svg" />
        </span>
      </a>
      
      <!-- <button (click)="ToFooter()" class="btn contactUs" type="button">
        <img style="margin-right: 10px;" src="../../../assets/images/human-greeting.svg" /> Contact Us Today
      </button> -->
    </form>
  </div>
</nav>
