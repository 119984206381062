import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './auth/auth.component'
import { ContactusComponent } from './auth/contactus/contactus.component';
import { PrivacyComponent } from './auth/privacy/privacy.component'
import { ProductsComponent } from './auth/products/products.component';
import { ResourcesComponent } from './auth/resources/resources.component';
import { AboutusComponent } from './auth/aboutus/aboutus.component';

const routes: Routes = [{
  path: '',
  component: AuthComponent
},
{
  path: 'AboutUs',
  component: AboutusComponent
},
{
  path: 'Products',
  component: ProductsComponent
},
{
  path: 'Resources',
  component: ResourcesComponent
},
{
  path: 'ContactUs',
  component: ContactusComponent
},
{
  path: 'privacy',
  component: PrivacyComponent
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
