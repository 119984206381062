import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { AuthComponent } from './auth.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ProductsComponent } from './products/products.component';
import { ResourcesComponent } from './resources/resources.component';
import { ContactusComponent } from './contactus/contactus.component';
import { TabsModule } from "./../components/tabs";
import{FormsModule,ReactiveFormsModule}from '@angular/forms';
import { AboutusComponent } from './aboutus/aboutus.component';

@NgModule({
  declarations: [
    AuthComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    PrivacyComponent,
    ProductsComponent,
    ResourcesComponent,
    ContactusComponent,
    AboutusComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    TabsModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    HomeComponent
  ]
})
export class AuthModule { }
