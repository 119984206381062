<div class="parent productPageMainSection">
  <div class="bottom productPageDetailSection">
    <h1 class="mainHeading">Products</h1>
  </div>
</div>
<div class="products">
  <div class="container-fluid">
    <div class="row pr-lgo-dv">
      <div class="col-lg-6" style="text-align: center;">
        <img src="../../../assets/images/MyJourney_logo_adobe.svg" class="pr-lgo">
        <p class="productTagLine">
          Our comprehensive suite provides a seamless experience throughout the breast cancer journey,
          designed to support high quality care.
        </p>
      </div>
      <div class="col-lg-6" style="text-align: center;">
        <img src="../../../assets/images/Subtract.svg" style="height: 450px;">
      </div>
    </div>
  </div>
</div>
<!--Tabs Section-->
<!-- <ngx-tabs>
    <ngx-tab tabTitle="Clinician Portal">
      <div class="container">
        <div class="row">
          <div class="col-lg-6" style="background: #D9D9D9;;">
            <div style="text-align: left;">
              <img src="../../../assets/images/clinicianPortal.svg" style="height: 560px;">
            </div>
          </div>
          <div class="col-lg-6" style="display: flex;justify-content: center;flex-flow: column;margin-top: 5rem;">
            <h5 style="margin-left: 1rem;font-family: 'Montserrat';font-size: 40px;line-height: 49px;letter-spacing: -0.02em;color: #383838;">Clinician Portal</h5>
            <div style="display: flex;align-items: center;justify-content: center;">
              <img src="../../../assets/images/Group314.svg" style="width: 54px;height: 54px;">
              <p class="patientPortalTagline">
                Easily monitor patients in each stage of their journey, from diagnosis to survivorship.
              </p>
            </div>
            <div style="display: flex;align-items: center;justify-content: center;">
              <img src="../../../assets/images/Group314.svg" style="width: 54px;height: 54px;">
              <p class="patientPortalTagline">
                Manage and adjust treatment plans based on patient progress.
              </p>
            </div>
            <div style="display: flex;align-items: center;justify-content: center;">
              <img src="../../../assets/images/Group314.svg" style="width: 54px;height: 54px;">
              <p class="patientPortalTagline">
                Provide patients with resources related to their journey.
              </p>
            </div>
          </div>
        </div>
      </div>
    </ngx-tab>
    <ngx-tab tabTitle="Patient Portal">
      <div class="container">
        <div class="row">
          <div class="col-lg-6" style="background: #D9D9D9;">
            <div style="text-align: left;">
              <img src="../../../assets/images/Mask-group.svg" style="margin-left: -4rem;">
            </div>
          </div>
          <div class="col-lg-6" style="display: flex;justify-content: center;flex-flow: column;margin-top: 5rem;">
            <h5 style="margin-left: 1rem;font-family: 'Montserrat';font-size: 40px;line-height: 49px;letter-spacing: -0.02em;color: #383838;">Patient Portal</h5>
            <div style="display: flex;align-items: center;justify-content: center;">
              <img src="../../../assets/images/Group314.svg" style="width: 54px;height: 54px;">
              <p class="patientPortalTagline">
                Access educational content and resources to stay informed on topics important to you.
              </p>
            </div>
            <div style="display: flex;align-items: center;justify-content: center;">
              <img src="../../../assets/images/Group314.svg" style="width: 54px;height: 54px;">
              <p class="patientPortalTagline">
                Keep track of your appointments, treatment plans, and health information all in one place.
              </p>
            </div>
            <div style="display: flex;align-items: center;justify-content: center;">
              <img src="../../../assets/images/Group314.svg" style="width: 54px;height: 54px;">
              <p class="patientPortalTagline">
                Stay connected to your care team throughout your journey.
              </p>
            </div>
          </div>
        </div>
      </div>
    </ngx-tab>
    <ngx-tab tabTitle="MCC">
      <div class="container">
        <div class="row">
          <div class="col-lg-6" style="background: #D9D9D9;">
            <div style="text-align: left;">
              <img src="../../../assets/images/Mccgroup.svg" style="height: 561px;">
            </div>
          </div>
          <div class="col-lg-6" style="display: flex;justify-content: center;flex-flow: column;margin-top: 5rem;">
            <h5 style="margin-left: 1rem;font-family: 'Montserrat';font-size: 40px;line-height: 49px;letter-spacing: -0.02em;color: #383838;">Multidisciplinary Cancer Conference Tool</h5>
            <div style="display: flex;align-items: center;justify-content: center;">
              <img src="../../../assets/images/Group314.svg" style="width: 54px;height: 54px;">
              <p class="patientPortalTagline">
                Easily host MCCs within your institution.
              </p>
            </div>
            <div style="display: flex;align-items: center;justify-content: center;">
              <img src="../../../assets/images/Group314.svg" style="width: 54px;height: 54px;">
              <p class="patientPortalTagline">
                Present conference materials in an organized structure.
              </p>
            </div>
            <div style="display: flex;align-items: center;justify-content: center;">
              <img src="../../../assets/images/Group314.svg" style="width: 54px;height: 54px;">
              <p class="patientPortalTagline">
                Improve the quality of patient care and treatment.
              </p>
            </div>
          </div>
        </div>
      </div>
    </ngx-tab>
  </ngx-tabs>
Tabs Section End -->

<!--Test Carousel-->
<div class="crousel">
  <div id="Works">
    <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
      <ol class="carousel-indicators" style="bottom: -70px">
        <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
      </ol>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <div class="row">
            <div class="col-md-12 text-center">
              <h5 class="cruselHeading">Clinician Portal</h5>
            </div>
          </div>
          <div class="row">
            <div class="col text-center img-bullets">
              <div class="col-lg-6 caro-img">
                <img src="../../../assets/images/clinicianPortal.svg" class="img-crosel">
              </div>
              <div class="col-lg-6 p-portal">
                <div style="display: flex;margin-top: 2rem;align-items: center;">
                  <img src="../../../assets/images/Group314.svg" class="pr-bullet">
                  <p class="patientPortalTagline">
                    Easily monitor patients in each stage of their journey, from diagnosis to survivorship.
                  </p>
                </div>
                <div style="display: flex;margin-top: 1rem;align-items: center;">
                  <img src="../../../assets/images/Group314.svg" class="pr-bullet">
                  <p class="patientPortalTagline">
                    Manage and adjust treatment plans based on patient progress.
                  </p>
                </div>
                <div style="display: flex;margin-top: 1rem;align-items: center;">
                  <img src="../../../assets/images/Group314.svg" class="pr-bullet">
                  <p class="patientPortalTagline">
                    Provide patients with resources related to their journey.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="row">
            <div class="col-md-12 text-center">
              <h5 class="cruselHeading">Patient Portal</h5>
            </div>
          </div>
          <div class="row">
            <div class="col text-center img-bullets" style="text-align: initial;">
              <div class="col-lg-6 caro-img">
                <img src="../../../assets/images/Mask-group.svg" class="img-crosel">
              </div>
              <div class="col-lg-6 p-portal">
                <div style="display: flex;margin-top: 2rem;align-items: center;">
                  <img src="../../../assets/images/Group314.svg" class="pr-bullet">
                  <p class="patientPortalTagline">
                    Access educational content and resources to stay informed on topics important to you.
                  </p>
                </div>
                <div style="display: flex;margin-top: 1rem;align-items: center;">
                  <img src="../../../assets/images/Group314.svg" class="pr-bullet">
                  <p class="patientPortalTagline">
                    Keep track of your appointments, treatment plans, and health information all in one place.
                  </p>
                </div>
                <div style="display: flex;margin-top: 1rem;align-items: center;">
                  <img src="../../../assets/images/Group314.svg" class="pr-bullet">
                  <p class="patientPortalTagline">
                    Stay connected to your care team throughout your journey.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="carousel-item">
          <div class="row">
            <div class="col-md-12 text-center">
              <h5 class="cruselHeading">Multidisciplinary Cancer Conference Tool (MCC)</h5>
            </div>
          </div>
          <div class="row">
            <div class="col text-center img-bullets" style="text-align: initial;">
              <div class="col-lg-6">
                <img src="../../../assets/images/Mccgroup.svg" class="img-crosel">
              </div>
              <div class="col-lg-6 p-portal">
                <div class="mcc-caroTop">
                  <img src="../../../assets/images/Group314.svg" class="pr-bullet">
                  <p class="patientPortalTagline">
                    Easily host MCCs within your institution.
                  </p>
                </div>
                <div class="mcc-caro">
                  <img src="../../../assets/images/Group314.svg" class="pr-bullet">
                  <p class="patientPortalTagline">
                    Present conference materials in an organized structure.
                  </p>
                </div>
                <div class="mcc-caro">
                  <img src="../../../assets/images/Group314.svg" class="pr-bullet">
                  <p class="patientPortalTagline">
                    Improve the quality of patient care and treatment.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a class="carousel-control-prev" href="#carouselExampleIndicators" data-slide="prev">
        <span class="carousel-control-prev-icon"></span>
      </a>
      <a class="carousel-control-next" href="#carouselExampleIndicators" data-slide="next">
        <span class="carousel-control-next-icon"></span>
      </a>
    </div>
  </div>
</div>


<!--Footer Section-->
<div class="homeFooterSection">
  <div class="homeFooterSectionMask">
    <section id="footer">
      <div class="container">
        <div class="row text-sm-left pt-5 text-md-left">
          <div *ngIf="IsSent" class="col-md-6">
            <div class="row">
              <div class="col-lg-10">
                <div [formGroup]="contactUsForm">
                  <div class="contact-form" style="
                  border-radius: 20px;
                  background-color: white;
                  padding: 20px;
                ">
                    <div class="form-group col-sm-12">
                      <label class="control-label footerHeading">Contact Us</label>
                    </div>

                    <div class="form-group col-sm-12 contact_profile">
                      <label class="control-label">Your Name</label>
                      <input type="text" class="form-control nameInput" id="fname" formControlName="name" placeholder="Enter Your Name"
                        name="fname" autocomplete="off" />
                    </div>
                    <div class="form-group">
                      <label class="control-label col-sm-12">Email</label>
                      <div class="col-sm-12 contact_email input">
                        <input type="email" class="form-control nameInput" id="email" formControlName="email" placeholder="Enter Your Email"
                          name="email" autocomplete="off" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" />
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="control-label col-sm-12">Message </label>
                      <div class="col-sm-12 comment_area">
                        <textarea class="form-control commentInput" rows="5" name="message" id="comment" formControlName="message"
                          placeholder="Write Your Message..." autocomplete="off"></textarea>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="col-sm-offset-2 col-sm-10">
                        <button type="button" class="btn btnContact" (click)="SendEmail()">
                          <img style="margin-right: 10px;margin-top: -0.1rem;" src="../../../assets/images/send.svg" /> Send Message
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <!--Succes-->
          <div *ngIf="!IsSent" class="col-md-6">
            <div class="row">
              <div class="col-lg-10">
                <div class="contact-form" style="
                  border-radius: 20px;
                  background-color: white;
                  padding: 20px;text-align: center;">
                  <div class="form-group col-lg-12" style="margin-top: 3rem;">
                    <img src="../../../assets/images/clickModify.svg">
                  </div>
                  <div class="form-group col-lg-12 t-msg" style="margin-top: 2rem;">
                    <p>Thank you!</p>
                  </div>
                  <div class="form-group col-lg-12 t-mdtl" style="margin-top: 1rem;margin-bottom: 4rem;">
                    <p>One of our representatives<br />
                      will be in touch shortly.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--Edn-->
          <div class="col-md-6 align-self-center">
            <div class="row">
              <div class="col-md-12">
                <h2 class="footerHeading">Join us on our journey for better</h2>
              </div>
              <div class="col-md-12">
                <h6 class="faddress">
                  Address: 4800 Leslie St , North York, ON M2J 2K9 <br />
                  Email: info@myjourney.health
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-5"></div>
          <hr />
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-5"></div>
          <hr />
        </div>
      </div>
    </section>
  </div>
</div>
<!--Footer Section End-->