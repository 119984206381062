<div class="parent contactUsPageMainSection">
  <div class="bottom contactUsPageDetailSection">
    <h1 class="mainHeading">Contact</h1>
  </div>
</div>
<!--Footer Section-->
<div class="homeFooterSection">
  <div class="homeFooterSectionMask">
    <section id="footer">
      <div class="container">
        <div class="row text-sm-left pt-5 text-md-left">
          <div *ngIf="IsSent" class="col-md-6">
            <div class="row">
              <div class="col-lg-10">
                <div [formGroup]="contactUsForm">
                  <div class="contact-form" style="
                  border-radius: 20px;
                  background-color: white;
                  padding: 20px;
                ">
                    <div class="form-group col-sm-12">
                      <label class="control-label footerHeading">Contact Us</label>
                    </div>

                    <div class="form-group col-sm-12 contact_profile">
                      <label class="control-label">Your Name</label>
                      <input type="text" class="form-control nameInput" id="fname" formControlName="name" placeholder="Enter Your Name"
                        name="fname" autocomplete="off" />
                    </div>
                    <div class="form-group">
                      <label class="control-label col-sm-12">Email</label>
                      <div class="col-sm-12 contact_email input">
                        <input type="email" class="form-control nameInput" id="email" formControlName="email" placeholder="Enter Your Email"
                          name="email" autocomplete="off" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" />
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="control-label col-sm-12">Message </label>
                      <div class="col-sm-12 comment_area">
                        <textarea class="form-control commentInput" rows="5" name="message" id="comment" formControlName="message"
                          placeholder="Write Your Message..." autocomplete="off"></textarea>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="col-sm-offset-2 col-sm-10">
                        <button type="button" class="btn btnContact" (click)="SendEmail()">
                          <img style="margin-right: 10px;margin-top: -0.1rem;" src="../../../assets/images/send.svg" /> Send Message
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <!--Succes-->
          <div *ngIf="!IsSent" class="col-md-6">
            <div class="row">
              <div class="col-lg-10">
                <div class="contact-form" style="
                  border-radius: 20px;
                  background-color: white;
                  padding: 20px;text-align: center;">
                  <div class="form-group col-lg-12" style="margin-top: 3rem;">
                    <img src="../../../assets/images/clickModify.svg">
                  </div>
                  <div class="form-group col-lg-12 t-msg" style="margin-top: 2rem;">
                    <p>Thank you!</p>
                  </div>
                  <div class="form-group col-lg-12 t-mdtl" style="margin-top: 1rem;margin-bottom: 4rem;">
                    <p>One of our representatives<br />
                      will be in touch shortly.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--Edn-->
          <div class="col-md-6 align-self-center">
            <div class="row">
              <div class="col-md-12">
                <h2 class="footerHeading">Join us on our journey for better</h2>
              </div>
              <div class="col-md-12">
                <h6 class="faddress">
                  Address: 4800 Leslie St , North York, ON M2J 2K9 <br />
                  Email: info@myjourney.health
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-5"></div>
          <hr />
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-5"></div>
          <hr />
        </div>
      </div>
    </section>
  </div>
</div>
<!--Footer Section End-->