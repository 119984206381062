import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { EmailService } from './../Services/email.service';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.scss']
})
export class AboutusComponent implements OnInit {

  constructor(private emailService: EmailService, private formBuilder: FormBuilder) { }
  
  IsSent: boolean = true;

  contactUsForm = this.formBuilder.group({
    name: '',
    email: '',
    message: ''
  });

  ngOnInit(): void {
  }

  clearForm() {
    this.contactUsForm.reset();
  }


  SendEmail() {
    this.emailService.SendEmail(this.contactUsForm.value).subscribe((Data: any) => {
      if (Data.status === "success") {
        this.clearForm();
        this.IsSent = false;
      } else {
      }
    });
  }

}
