import { Component, OnInit, ChangeDetectionStrategy  } from '@angular/core';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {

  constructor() { }
  
  ngOnInit(): void {
  //window.addEventListener('scroll', this.scroll, true);
  }
  ToWork(){
  document.getElementById("Works").scrollIntoView({behavior:"smooth"});
  
  }
  ToAffliates(){
  document.getElementById("Partnerships").scrollIntoView({behavior:"smooth"});
  }
  ToFooter(){
  document.getElementById("footer").scrollIntoView({behavior:"smooth"});
  
  }
  // scroll = (event): void => {
  // var WINDOW_HEIGHT = window.innerHeight || document.documentElement.clientHeight;
  // var centerPoint=document.body.scrollHeight/2;
  // /*Image Animation */
  // if(event.srcElement.scrollingElement.scrollTop>(centerPoint-WINDOW_HEIGHT))
  // {
  // document.getElementById("LeftFirstImage").classList.add("LeftToRightAnimation");
  // document.getElementById("LeftSecondImage").classList.add("LeftToRightAnimation");
  // document.getElementById("RightFirstImage").classList.add("RightToLeftAnimation");
  // document.getElementById("RightSecondImage").classList.add("RightToLeftAnimation");
  // }else{
  // document.getElementById("LeftFirstImage").classList.remove("LeftToRightAnimation");
  // document.getElementById("LeftSecondImage").classList.remove("LeftToRightAnimation");
  // document.getElementById("RightFirstImage").classList.remove("RightToLeftAnimation");
  // document.getElementById("RightSecondImage").classList.remove("RightToLeftAnimation");
  // }
  // /*Heading Animation */
  // if(event.srcElement.scrollingElement.scrollTop>10)
  // {
  // document.getElementById("MyJourney").classList.add("BottomToTopAnimation");
  // }else{
  // document.getElementById("MyJourney").classList.remove("BottomToTopAnimation");
  // }
  // };
  
  }