<div class="parent homePageMainSection">
  <div class="bottom homePageDetailSection">
    <h1 class="mainHeading">Cancer care can be overwhelming.</h1>
    <h1 class="mainHeading">Make it easier with MyJourney.</h1>
    <div class="row">
      <div class="col-md-7">
        <p class="mainSubHeading">The MyJourney™ platform provides digital tools for patients and caregivers to navigate
          each step of the breast cancer journey, from diagnosis to survivorship.</p>
      </div>
      <!-- <div class="col-md-5" style="top: 50px;">
       <a style="text-decoration: none;" href="Products"><span style="color: #FFFFFF;letter-spacing: 0.05em; font-weight: 600;font-family: 'Poppins';font-size: 16px;">LEARN MORE</span></a> 
        <a  href="ContactUs"><button class="btnStarted">GET STARTED</button></a>
      </div> -->
    </div>
  </div>
</div>
<div class="partners">
  <p class="partnerLabel">Our Trusted Partners</p>
  <div class="container-fluid"> 
    <div class="row p-nr">
    <div class="col-lg-4" style="margin-top: 2rem;">
      <img src="../../../assets/images/NewYarkGeneral.svg" style="width: 100%; height: 12vh;">
    </div>
    <div class="col-lg-4" style="margin-top: 2rem;">
      <img src="../../../assets/images/CanCancerSociety.svg" style="width: 100%; height: 12vh;">
    </div>
    <div class="col-lg-4" style="margin-top: 2rem;">
      <img src="../../../assets/images/RETHINK.svg" class="re-tnk">
    </div>
    <!-- <div class="col-lg-3">
      <img src="../../../assets/images/sunnybrookhsc.svg" style="width: 100%;height: 62px;">
    </div> -->
  </div>
  </div>
  
</div>


<div class="box-space-top">

  <div class="col-md-12 text-center my-jorn">
      <h2 class="MianHeading">Why MyJourney™?</h2>
    </div>

  <div class="row">
    <div class="col-md-6 text-center">
      <div class="percontent">
        <h4 class="personalize">Personalized Content</h4>
      <h4 class="mainParagrap">
        Tailored treatment plans and educational content
      </h4>
      </div>
      
    </div>
    <div class="col-md-6">
      <img src="../../../assets/images/personalizeContent.svg" style="width: 100%;">
    </div>
  </div>

  <div class="row" style="margin-top: 8rem;">
    <div class="col-md-6">
      <img src="../../../assets/images/stayconnected.svg" style="width: 100%;">
    </div>
    <div class="col-md-6">
      <div style="position: relative;top: 45%;">
        <h4 class="personalize">Stay Connected</h4>
        <h4 class="mainParagrap" style="text-align: center;">
          Health information, resources, and scheduling,<br/> all in one place
        </h4>
      </div>
    </div>
  </div>

  <div class="row" style="margin-top: 8rem;">
    <div class="col-md-6">
      <div class="percontent">
        <h4 class="personalize">Follow the Journey</h4>
        <h4 class="mainParagrap" style="text-align: center;">
          Keep track of each step along the way with ease
        </h4>
      </div>
    </div>
    <div class="col-md-6">
      <img src="../../../assets/images/followJourney.svg" style="width: 100%;">
    </div>
  </div>


  <div class="homeFooterSection">
    <div class="homeFooterSectionMask">
      <div class="row ft-explore">
        <div class="col-md-7">
          <p class="explore">Explore our product suite</p>
          <p class="exploreDetail">The MyJourney™ platform provides digital tools for patients and caregivers to navigate
            each step of the breast cancer journey, from diagnosis to survivorship.</p>
        </div>
        <div class="col-md-5" style="text-align: center;">
          <div style="position: relative;top: 60%;">
           <a href="Products"><button class="btnProducts">PRODUCTS</button></a> 
            <a href="ContactUs"><button class="btnContactus">CONTACT US</button></a> 
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
